<template>
    <div>
        <!-- 顶部工具条 -->
        <el-col :span="24">
            <el-form :inline="true">
                <el-form-item label='关键字:'>
                    <el-input placeholder="姓名/身份证/手机号/单位名称"
                        v-model="filters.searchKey"
                        clearable
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="数据来源:">
                    <el-select v-model="filters.dataSource" clearable placeholder="请选择">
                        <el-option
                            v-for="item in dataSourceArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label='省：'>
                    <el-select v-model="filters.provinceID" clearable @change='selProvince' placeholder="请选择">
                        <el-option
                            v-for="item in provinceIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <!-- <el-form-item label='市：'>
                    <el-select v-model="filters.cityID" clearable  @change='selCity' placeholder="请选择">
                        <el-option
                            v-for="item in cityIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='区/县：'>
                    <el-select v-model="filters.regionID" clearable placeholder="请选择">
                        <el-option
                            v-for="item in regionIDArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item> -->
                <el-form-item label="地区:">
                        <el-cascader :props="props" v-model="filters.selecAreaArr" clearable></el-cascader>
                    </el-form-item>
                <el-form-item label="岗位:" clearable placeholder="请选择岗位">
                    <el-select v-model="filters.station" >
                        <el-option 
                            v-for="item in stationArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="行业:" clearable placeholder="请选择行业">
                    <el-select v-model="filters.trade" >
                        <el-option 
                            v-for="item in tradeArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="免培考试权力:" clearable placeholder="请选择免培考试权力">
                    <el-select v-model="filters.examNoTrainStatus" clearable>
                        <el-option 
                            v-for="item in examNoTrainStatusArr"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                </el-form-item>
            </el-form>
        </el-col>
        <!-- 表格 -->
        <el-table 
            v-loading="tableLoading" 
            :data="tableData" 
            style="width:100%" 
            border 
            ref="multipleTable"
            highlight-current-row 
            @current-change="handlerowChange"
            @selection-change="handleSelectChange"
        >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column label="姓名" width="" align="center" prop="Name"></el-table-column>
            <el-table-column label="身份证号" min-width="130" align="center" prop="CardNo"></el-table-column>
            <el-table-column label="手机号" width="" align="center" prop="PhoneNumber"></el-table-column>
            <el-table-column  label="数据来源" min-width="100" align="center" prop="RequestSource">
                <!-- <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.QuestionsType == 1">iOS</el-tag>
                    <el-tag :type="'warning'" v-if="scope.row.QuestionsType == 2">Android</el-tag>
                </template> -->
            </el-table-column>
            <el-table-column  label="单位名称" min-width="100" align="center" prop="UnitName"></el-table-column>
            <!-- <el-table-column  label="单位所在省" width="" align="center" prop="Province"></el-table-column> -->
            <el-table-column  label="单位所在市" min-width="110" align="center" prop="City"></el-table-column>
            <el-table-column  label="单位所在区" min-width="110" align="center" prop="Region"></el-table-column>
            <el-table-column  label="岗位名称" min-width="100" align="center" prop="StationName"></el-table-column>
            <el-table-column  label="行业名称" min-width="100" align="center" prop="TradeName"></el-table-column>
            <el-table-column  label="报名时间" width="110" align="center" prop="CreateTime"></el-table-column>
            <el-table-column  label="免培考试截至时间" min-width="150" align="center" prop="OverdueTime"></el-table-column>
            <el-table-column  label="免培考试权力" min-width="120" align="center" prop="ExamNoTrainStatus">
                <template slot-scope="scope">
                    <el-tag :type="'success'" v-if="scope.row.ExamNoTrainStatus == 1">已使用</el-tag>
                    <el-tag :type="'warning'" v-else-if="scope.row.ExamNoTrainStatus == 2">待使用</el-tag>
                    <el-tag type="" v-else-if="scope.row.ExamNoTrainStatus == 3">未使用</el-tag>
                    <el-tag type="danger" v-else>未知</el-tag>
                </template>
            </el-table-column>
            <el-table-column  label="免培考试交卷时间" min-width="150" align="center" prop="HandTime"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div>
            <div>
                <el-pagination
                    small
                    :page-sizes="pages.pageArr"
                    :page-size="pages.pageSize"
                    :current-page="pages.pageIndex"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.dataCount"
                    @size-change="handleSizeChange" 
                    @current-change="handleCurrentChange"
                    class="pageBar"       
                    >
                </el-pagination>
            </div>
        </el-col>
        <!-- 生成批次 -->
        <el-dialog
            title="生成批次"
            :visible.sync="generateBatchDialog"
            v-model="generateBatchDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="generateBatchForm" :rules="generateBatchFormRules" ref="generateBatchFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='批次名称:' prop='batchName'>
                    <el-input v-model='generateBatchForm.batchName' clearable></el-input>
                </el-form-item>
                <div style="text-align: right;font-size: 16px;">批次人数：{{selectionData.length}}人</div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="generateBatchDialog = false">取消</el-button>
                <el-button type="primary" @click.native="generateBatchSubmit" :loading="generateBatchLoading">保存</el-button>
            </div>
        </el-dialog>
        <!-- 加入待处理批次 -->
        <el-dialog
            title="加入待处理批次"
            :visible.sync="addBatchDialog"
            v-model="addBatchDialog"
            :close-on-click-modal="false"
        >
            <div style="line-height:38px;" v-for="(item,index) in bathArr" :key="index">
                <el-radio v-model="selectBatchRadio" :label="item.ID">{{item.Name}}</el-radio>
            </div>
            <el-pagination
                layout="prev, pager, next"
                @current-change="addBathArrChange"
                :current-page="this.addBathIndex"
                :total="bathArr.length">
            </el-pagination>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addBatchDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addBatchSubmit" :loading="addBatchLoading">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Qs from 'qs' 
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
// 数据请求
import { getExamEnrollInfoListPage,getDataDictionaryList,getAreaDictionaryList,generateBatch,getExamBatchPopulationList,addBatch } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList: [],
            filters:{
                searchKey:'',
                dataSource:'',
                provinceID: 1919,
                cityID: '',
                regionID: '',
                selecAreaArr: [],
                station: '',
                trade: '',
                examNoTrainStatus: '',
            },
            dataSourceArr: [
                {label: "iOS",value: 0},
                {label: "Android",value: 1},
            ],
            provinceIDArr: [],
            cityIDArr: [],
            regionIDArr: [],
            stationArr: [], //岗位
            tradeArr: [], //行业
            examNoTrainStatusArr: [
                {name: "已使用",value: 1},
                {name: "待使用",value: 2},
                {name: "未使用",value: 3},
            ],
            // 表格
            tableData: [],
            tableLoading: false,
            allSelect: '',
            currentRow: '',
            // 分页
            pages:{
                pageArr:[20,60,100,200,300],
                pageSize:20,
                pageIndex:1,
                dataCount:1,
            },
            // 生成批次
            generateBatchDialog: false,
            generateBatchLoading: false,
            selectionData: [],
            generateBatchForm: {
                batchName: "",
            },
            generateBatchFormRules:{
                batchName:[{required:true,message:'请输入批次名称',trigger:'blur'}],
            },
            // 加入待处理批次
            addBatchDialog: false,
            addBatchLoading: false,
            bathArr: [],
            addBathIndex: 1,
            selectBatchRadio: "",
            // 地区级联选择
            props: {
                lazy: true,
                checkStrictly: true,
                lazyLoad (node, resolve) {
                    var level = false
                    if(node.value > 0) {
                        var params = {
                            parentId: node.value,
                            level: 2,
                        }
                        level = true
                    } else {
                        var params = {
                            parentId: 1919,
                            level: 1,
                        }
                        level = false
                    }
                    getAreaDictionaryList(params).then(res => {
                        if(res.data.Success){
                            var nodes = res.data.Response.reverse()
                            nodes.forEach(item => {
                                item.value = item.ID
                                item.label = item.Name
                                item.leaf = level
                            })
                            resolve(nodes);
                        }
                    })
                }
            },
        }
    },
    methods: {
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getExamBatchPopulationList() {
            this.getTableDataFun();
        },
        // 列表
        getTableDataFun() {
            var params = {
                keyWord: this.filters.searchKey,
                examBatchPopulationID: this.filters.examBatch,
                provinceID: this.filters.provinceID,
                // cityId: this.filters.cityID,
                // regionId: this.filters.regionID,
                stationID: this.filters.station,
                tradeID: this.filters.trade,
                examNoTrainStatus: this.filters.examNoTrainStatus,
                batchStatus: 2,
                isExemptTrain: 1,
                isEnable: true,
                pageIndex: this.pages.pageIndex,
                pageSize: this.pages.pageSize,
            }
            if(this.filters.selecAreaArr.length > 0) {
                params.cityID = this.filters.selecAreaArr[0]
            }
            if(this.filters.selecAreaArr.length > 1) {
                params.regionID = this.filters.selecAreaArr[1]
            }
            this.tableLoading = true
            getExamEnrollInfoListPage(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tableLoading = false
                    this.tableData = result.Response.Data
                    this.pages.dataCount = result.Response.DataCount
                }
            })
        },
        // 生成批次
        generateBatch() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择需要生成批次的数据！")
            } else {
                // this.generateBatchLoading = false
                // this.generateBatchDialog = true
                // this.generateBatchForm.batchName = ""
                this.$confirm(`已选择` + this.selectionData.length + `人，确定生成批次`,"提示",{
                }).then(() => {
                    this.generateBatchSubmit()
                })
            }
        },
        generateBatchSubmit() {
            // this.$refs['generateBatchFormRef'].validate(valid => {
            //     if(valid){
                    var ids = []
                    this.selectionData.forEach(item => {
                        ids.push(item.ID)
                    });
                    var params = {
                        ExamEnrollInfoIds: ids,
                        // Name: this.generateBatchForm.batchName,
                    }
                    this.generateBatchLoading = true
                    generateBatch(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            // this.generateBatchDialog = false
                            // this.getTableDataFun();
                        } else {
                            this.$message.error(result.Message)
                        }
                        this.generateBatchLoading = false
                    })
            //     }
            // })
        },
        // 加入待处理批次
        addPendingBatch() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择需要加入待处理批次的数据！")
            } else {
                if(this.bathArr.length > 0) {
                    this.addBatchDialog = true
                    this.addBatchLoading = false
                    this.selectBatchRadio = ""
                } else {
                    var params = {
                        pageIndex: this.addBathIndex,
                        status: 0,
                        pageSize: 20,
                    }
                    getExamBatchPopulationList(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.bathArr = result.Response.Data
                            this.addBatchDialog = true
                            this.addBatchLoading = false
                            this.selectBatchRadio = ""
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                }
            }
        },
        addBatchSubmit() {
            if(this.selectBatchRadio) {
                var ids = []
                this.selectionData.forEach(item => {
                    ids.push(item.ID)
                });
                var params = {
                    ExamEnrollInfoIds: ids,
                    ExamBatchPopulationID: this.selectBatchRadio,
                }
                this.addBatchLoading = true
                addBatch(params).then((res) => {
                    var result = res.data
                    if(result.Success) {
                        this.$message.success(result.Message)
                        this.getTableDataFun()
                        this.addBatchDialog = false
                    } else {
                        this.$message.error(result.Message)
                    }
                    this.addBatchLoading = false
                })
            } else {
                this.$message.warning("请选择要加入的待处理批次！")
            }
        },
        addBathArrChange(val) {
            this.addBathIndex = val
            this.getBatchFun()
        },
        // 获取待处理批次
        getBatchFun() {
            var params = {
                pageIndex: this.addBathIndex,
                status: 0,
                pageSize: 20,
            }
            getExamBatchPopulationList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.bathArr = result.Response.Data
                }
            })
        },
        // 分页
        handleSizeChange(newSize){
            this.pages.pageSize = newSize   
            this.getTableDataFun()
        },
        handleCurrentChange(newPage){
            this.pages.pageIndex = newPage      
            this.getTableDataFun()
        },
        // 表格当前行改变事件
        handlerowChange(currentRow){
            this.currentRow = currentRow
        },
        // 选中表格行
        handleSelectChange(allRow){
            this.allSelect = allRow
        },
        // 获取岗位/行业
        getDataDictionaryList() {
            var params = {}
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.tradeArr.push(item)
                        } else if(item.DataType == 1) {
                            this.stationArr.push(item)
                        }
                    });
                }
            })
        },
        // 选中省、市、区当前行
        selProvince(value){
            this.filters.cityID = ''
            this.filters.regionID = ''
            this.parentProvinceID = value
            var params = {
                level:1,
                parentId:this.parentProvinceID
            }
            this.getCity(params)
        },
        selCity(value){
            this.parentCityID = value
            var params = {
                parentId:this.parentCityID
            }
            this.getArea(params)
        },
        // 获取省、市、区的数据
        getProvince(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.provinceIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getCity(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.cityIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        getArea(params){
            getAreaDictionaryList(params).then(res => {
                if(res.data.Success){
                    this.regionIDArr = res.data.Response.reverse()
                }else{return}
            }).catch(() => {})
        },
        // 时间格式化
        formatCreateTime:function(row,column){
            return !row.CreateTime || row.CreateTime == ""
            ? ""
            : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
        }, 
    },
    created(){
        this.getTableDataFun();
        this.getDataDictionaryList();
        // var params = {
        //     level:0
        // }
        // this.getProvince(params)
        var params = {
            parentId: 1919,
            level: 1,
        }
        // this.getCity(params)
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>